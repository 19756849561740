<template lang="pug">
  .referral
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Списать

</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'Referral',

  components: {
    InputSmart,
    SectionHeader,
  },

  data: () => ({
    items: {
      id: {
        title: 'ID основного аккаунта',
        value: null,
        type: 'number',
        placeholder: 'Введите ID',
      }
    }
  }),

  computed: {
    header() {
      return {
        title: 'Массовое списание по Ref ID'
      }
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const mainAccountId = this.items.id.value

        const { data } = await this.$axios.post(`/admin/user/${mainAccountId}/referral-debiting`)
        const amount = data?.data?.amount

        if (amount) {
          const amountStr = (amount / 100).toFixed(2)
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: `Списано ${amountStr} EUR. Не забудь начислить их основному акканту (ID=${mainAccountId})`,
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>

<style lang="scss">
</style>
